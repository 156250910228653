import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { StartseiteComponent } from './startseite/startseite.component';
import { ZeichnenComponent } from './zeichnen/zeichnen.component';
import { PlastizierenComponent } from './plastizieren/plastizieren.component';
import { KunsttherapieFuerKinderComponent } from './kunsttherapie-fuer-kinder/kunsttherapie-fuer-kinder.component';
import { KurseFuerErwachseneComponent } from './kurse-fuer-erwachsene/kurse-fuer-erwachsene.component';
import { KurseFuerKinderComponent } from './kurse-fuer-kinder/kurse-fuer-kinder.component';
import { AusstellungenComponent } from './ausstellungen/ausstellungen.component';
import { GallerieComponent } from './gallerie/gallerie.component';
import { ZurPersonComponent } from './zur-person/zur-person.component';
import { KontaktComponent } from './kontakt/kontakt.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { EigeneArbeitenComponent } from './eigene-arbeiten/eigene-arbeiten.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';

@NgModule({
  declarations: [
    AppComponent,
    StartseiteComponent,
    ZeichnenComponent,
    PlastizierenComponent,
    KunsttherapieFuerKinderComponent,
    KurseFuerErwachseneComponent,
    KurseFuerKinderComponent,
    AusstellungenComponent,
    GallerieComponent,
    ZurPersonComponent,
    KontaktComponent,
    ImpressumComponent,
    EigeneArbeitenComponent,
    DatenschutzComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    RouterModule.forRoot([
      {
        path: 'startseite',
        component: StartseiteComponent
      },
      {
        path: 'zeichnen',
        component: ZeichnenComponent
      },
      {
        path: 'plastizieren',
        component: PlastizierenComponent
      },
      {
        path: 'kunsttherapie-fuer-kinder',
        component: KunsttherapieFuerKinderComponent
      },
      {
        path: 'kurse-fuer-erwachsene',
        component: KurseFuerErwachseneComponent
      },
      {
        path: 'kurse-fuer-kinder',
        component: KurseFuerKinderComponent
      },
      {
        path: 'ausstellungen',
        component: AusstellungenComponent
      },
      {
        path: 'eigene-arbeiten',
        component: EigeneArbeitenComponent
      },
      {
        path: 'gallerie',
        component: GallerieComponent
      },
      {
        path: 'zur-person',
        component: ZurPersonComponent
      },
      {
        path: 'kontakt',
        component: KontaktComponent
      },
      {
        path: 'impressum',
        component: ImpressumComponent
      },
      {
        path: 'datenschutz',
        component: DatenschutzComponent
      },
      {
        path: '',
        component: StartseiteComponent
      },
    ])
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
