import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-zur-person',
  templateUrl: './zur-person.component.html',
})
export class ZurPersonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
