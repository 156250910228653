import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kunsttherapie-fuer-kinder',
  templateUrl: './kunsttherapie-fuer-kinder.component.html',
})
export class KunsttherapieFuerKinderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
