import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-zeichnen',
  templateUrl: './zeichnen.component.html',
})
export class ZeichnenComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
