import { Component, OnInit } from '@angular/core';
// import { HttpClient, HttpHeaders} from '@angular/common/http';
// import { Observable } from 'rxjs/Observable';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/catch';

@Component({
  selector: 'app-kontakt',
  templateUrl: './kontakt.component.html',
})
export class KontaktComponent implements OnInit {

  // private contactUrl = '/assets/contact.php';

  // submitted = false;

  // message = {
  //   name: '',
  //   email: '',
  //   phone: '',
  //   text: ''
  // };

  // constructor(private http: HttpClient) { }

  ngOnInit() {
  }

  // onSubmit() {
  //   this.submitted = true;
  //   this.postEmail(this.message).subscribe(
  //     response => this.handleResponse(response),
  //     error => this.handleResponse(error)
  //   );
  // }

  // handleResponse(response: any) {
  //   // console.log(`msg is: {response.status}`);

  //   if (response.status === 'success') {

  //   }

  //   if (response.status === 'error') {

  //   }
  // }

  // postEmail(message: any): Observable<string> {
  //   // const body = `name=${message.name}&email=${message.email}&phone=${message.phone}&text=${message.text}`;
  //   const body = JSON.stringify(message);

  //   const headers = new HttpHeaders();
  //   headers.append('Content-Type', 'application/json');

  //   return this.http.post(this.contactUrl, body, {headers: headers})
  //     .catch(err => {
  //       console.error('Error in sending contact form: ' + err);
  //       return Observable.throw(err.json().error || 'Server error');
  //     });
  // }

}
